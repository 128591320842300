<template>

  <div>
    <section class="selfcare-title d-flex">
      <div class="px-2">
        <a @click="goBack()" class="custom-link text-light">
          <i class="bi bi-arrow-left"></i>
        </a>
      </div>
      <div class="text-light width-available text-center">
        <strong>
          Offers & Promotions
        </strong>
      </div>
    </section>

    <div class="promotions">
      <div class="list">
        <div class="card" v-for="(promotion, index) in promotions" :key="index">
          <img loading="lazy" :src="`/img/carousel/`+promotion.img" alt="Promotion image"/>

          <div class="content">
            <h3>{{ promotion.title }}</h3>
            <p>{{ promotion.description }}</p>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>

export default {
  name: 'Offers',
  data() {
    return {
      promotions: [
        {
          title: "Karibu Bonus - Get free cash on registration",
          description: "Start your winning journey right away! Sign up and instantly receive free cash to boost your bets. " +
              "No deposits, no strings attached. Claim your free boost and experience the thrill of online betting, risk-free!",
          img: "karibu.png"
        },
        {
          title: "Daily Jackpot - Win KSH. 2,000,000",
          description: "Every day, KSH. 2,000,000 could be yours! Dive into the Daily Jackpot and grab your chance to " +
              "become an instant millionaire. Place your bets, cross your fingers, and witness the jackpot counter tick " +
              "toward life-changing riches. Today could be your lucky day - play now and claim your victory!",
          img: "djp.png"
        },
        // Add more promotions with titles and descriptions ...
      ],
    };
  },
  components: {},
  mounted: function () {

    this.$store.dispatch("setCurrentPage", "offers");
    this.reloadProfile();

  }

}
</script>

<style scoped>
.promotions {
  padding: 20px;
}

.title {
  font-size: 24px;
  margin-bottom: 20px;
}

.list {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  flex-direction: column;
  color: #000000;
}

.card {
  width: 100%;
  flex-grow: 1;
  background-color: var(--lightest-gray1);;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 15px;
  color: #fff;
}

.card img {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.card h3 {
  font-size: 18px;
  margin: .5em 0;
}

.card p {
  line-height: 1.6;
}
</style>